import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const currencies = [
  { code: 'USD', symbol: '$' },
  { code: 'KD', symbol: 'KD' },
  { code: 'SAR', symbol: 'SAR' },
  { code: 'AED', symbol: 'AED' },
  { code: 'BHD', symbol: 'BHD' },
  { code: 'QAR', symbol: 'QAR' },
  { code: 'OMR', symbol: 'OMR' },
  { code: 'EUR', symbol: '€' },
  { code: 'GBP', symbol: '£' },
];

const languages = [
  { code: 'en', name: 'English' },
  { code: 'ar', name: 'عربي' },
  { code: 'fr', name: 'Français' },
];

function LanguageCurrencySelector({ language, setLanguage, currency, setCurrency }) {
  const { t } = useTranslation();
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);

  const toggleLanguage = () => {
    const currentIndex = languages.findIndex(lang => lang.code === language);
    const nextIndex = (currentIndex + 1) % languages.length;
    const newLanguage = languages[nextIndex].code;
    setLanguage(newLanguage);
  };

  const handleCurrencyChange = (newCurrency) => {
    setCurrency(newCurrency);
    setShowCurrencyDropdown(false);
  };

  const nextLanguage = languages.find(lang => lang.code !== language);

  return (
    <div className="flex items-center">
      <div className="relative mr-4">
        <button
          onClick={() => setShowCurrencyDropdown(!showCurrencyDropdown)}
          className="flex items-center bg-white px-2 py-1 rounded"
        >
          {currencies.find(c => c.code === currency).symbol}
          <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </button>
        {showCurrencyDropdown && (
          <div className="absolute top-full left-0 bg-white border rounded shadow-lg mt-1">
            {currencies.map((c) => (
              <button
                key={c.code}
                onClick={() => handleCurrencyChange(c.code)}
                className="block w-full text-left px-4 py-2 hover:bg-gray-100"
              >
                {c.symbol} {c.code}
              </button>
            ))}
          </div>
        )}
      </div>
      <button onClick={toggleLanguage} className="text-sm bg-white px-2 py-1 rounded">
        {/* removed a "switch to" inside those parenthesis below */}
        {t('')} {nextLanguage.name}
      </button>
    </div>
  );
}

export default LanguageCurrencySelector;

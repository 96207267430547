import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../contexts/CartContext';
import { useNavigate } from 'react-router-dom';

const donationTypes = ['Zakat', 'Sadaqat', 'Kafarat Yameen', 'Expiation Fasting', 'Nazoor or Sacrifice', 'Aqeeqa'];

function ZakatCard({ project, isHorizontal = false }) {
  const { t } = useTranslation();
  const [donationAmount, setDonationAmount] = useState(10);
  const [selectedType, setSelectedType] = useState('');
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();

  const handleAddToCart = () => {
    if (selectedType && donationAmount > 0) {
      const item = {
        id: project.id || 'zakat-1',
        name: `${project.name} - ${selectedType}`,
        donationAmount: parseFloat(donationAmount),
        isZakat: selectedType === 'Zakat'
      };
      console.log('ZakatCard: Adding to cart:', item);
      addToCart(item);
    } else {
      console.log('ZakatCard: Invalid input');
    }
  };

  const handleDonateNow = () => {
    if (selectedType && donationAmount > 0) {
      const item = {
        id: project.id || 'zakat-1',
        name: `${project.name} - ${selectedType}`,
        donationAmount: parseFloat(donationAmount),
        isZakat: selectedType === 'Zakat'
      };
      addToCart(item);
      navigate('/payment', { 
        state: { 
          project: {
            ...project,
            name: `${project.name} - ${selectedType}`,
            isZakat: selectedType === 'Zakat'
          },
          donationAmount: parseFloat(donationAmount),
          isZakat: selectedType === 'Zakat'
        } 
      });
    } else {
      console.log('ZakatCard: Invalid input for donation');
    }
  };

  return (
    <div className={`bg-white shadow-lg rounded-lg overflow-hidden ${isHorizontal ? 'md:flex' : ''} h-full`}>
      <img 
        src={project.image_url} 
        alt={project.name} 
        className={`${isHorizontal ? 'md:w-1/2 h-64 md:h-auto object-cover' : 'w-full h-48 object-cover'}`} 
      />
      <div className="p-4 flex-grow flex flex-col justify-between">
        <div>
          <h2 className="text-xl font-bold mb-2">{t(project.name)}</h2>
          <p className="mb-4">{t('The purpose of the donation')}</p>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 mb-4">
            {donationTypes.map((type) => (
              <button
                key={type}
                className={`px-2 py-1 rounded text-sm ${selectedType === type ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setSelectedType(type)}
              >
                {t(type)}
              </button>
            ))}
          </div>
        </div>
        <div className="mt-auto">
          <p className="mb-2">{t('Enter your donation')}</p>
          <div className="flex mb-4">
            <input
              type="number"
              value={donationAmount}
              onChange={(e) => setDonationAmount(parseFloat(e.target.value))}
              className="flex-grow border rounded-l px-2 py-1"
            />
            <span className="bg-green-500 text-white px-2 py-1 rounded-r">$</span>
          </div>
          <div className="flex flex-col sm:flex-row gap-2">
            <button
              className="w-full sm:w-1/2 bg-green-500 text-white px-4 py-2 rounded"
              onClick={handleDonateNow}
            >
              {t('Donate Now')}
            </button>
            <button
              className="w-full sm:w-1/2 border border-green-500 text-green-500 px-4 py-2 rounded"
              onClick={handleAddToCart}
            >
              {t('Add to cart')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZakatCard;


// import React, { useState, useContext } from 'react';
// import { useTranslation } from 'react-i18next';
// import { CartContext } from '../contexts/CartContext';
// import { useNavigate } from 'react-router-dom';

// const donationTypes = ['zakat', 'sadaqat', 'kafaratYameen', 'expiationFasting', 'nazoorOrSacrifice', 'aqeeqa'];

// function ZakatCard({ project, isHorizontal = false }) {
//   const { t } = useTranslation();
//   const [donationAmount, setDonationAmount] = useState(10);
//   const [selectedType, setSelectedType] = useState('');
//   const { addToCart } = useContext(CartContext);
//   const navigate = useNavigate();

//   const handleAddToCart = () => {
//     if (selectedType && donationAmount > 0) {
//       const item = {
//         id: project.id || 'zakat-1',
//         name: `${project.name} - ${t(`donationTypes.${selectedType}`)}`,
//         donationAmount: parseFloat(donationAmount),
//         isZakat: selectedType === 'zakat'
//       };
//       console.log('ZakatCard: Adding to cart:', item);
//       addToCart(item);
//     } else {
//       console.log('ZakatCard: Invalid input');
//     }
//   };

//   const handleDonateNow = () => {
//     if (selectedType && donationAmount > 0) {
//       const item = {
//         id: project.id || 'zakat-1',
//         name: `${project.name} - ${t(`donationTypes.${selectedType}`)}`,
//         donationAmount: parseFloat(donationAmount),
//         isZakat: selectedType === 'zakat'
//       };
//       addToCart(item);
//       navigate('/payment', { 
//         state: { 
//           project: {
//             ...project,
//             name: `${project.name} - ${t(`donationTypes.${selectedType}`)}`,
//             isZakat: selectedType === 'zakat'
//           },
//           donationAmount: parseFloat(donationAmount),
//           isZakat: selectedType === 'zakat'
//         } 
//       });
//     } else {
//       console.log('ZakatCard: Invalid input for donation');
//     }
//   };

//   return (
//     <div className={`bg-white shadow-lg rounded-lg overflow-hidden ${isHorizontal ? 'flex' : ''} h-full`}>
//       <img 
//         src={project.image_url} 
//         alt={project.name} 
//         className={`${isHorizontal ? 'w-1/2 object-cover' : 'w-full h-48 object-cover'}`} 
//       />
//       <div className="p-4 flex-grow flex flex-col justify-between">
//         <div>
//           <h2 className="text-xl font-bold mb-2">{t(project.name)}</h2>
//           <p className="mb-4">{t('donationCard.enterDonation')}</p>
//           <div className="grid grid-cols-2 gap-2 mb-4">
//             {donationTypes.map((type) => (
//               <button
//                 key={type}
//                 className={`px-2 py-1 rounded ${selectedType === type ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
//                 onClick={() => setSelectedType(type)}
//               >
//                 {t(`donationTypes.${type}`)}
//               </button>
//             ))}
//           </div>
//         </div>
//         <div className="mt-auto">
//           <p className="mb-2">{t('donationCard.enterDonation')}</p>
//           <div className="flex mb-4">
//             <input
//               type="number"
//               value={donationAmount}
//               onChange={(e) => setDonationAmount(parseFloat(e.target.value))}
//               className="flex-grow border rounded-l px-2 py-1"
//             />
//             <span className="bg-green-500 text-white px-2 py-1 rounded-r">$</span>
//           </div>
//           <div className="flex gap-2">
//             <button
//               className="flex-1 bg-green-500 text-white px-4 py-2 rounded"
//               onClick={handleDonateNow}
//             >
//               {t('donationCard.donateNow')}
//             </button>
//             <button
//               className="flex-1 border border-green-500 text-green-500 px-4 py-2 rounded"
//               onClick={handleAddToCart}
//             >
//               {t('donationCard.addToCart')}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ZakatCard;

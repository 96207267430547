import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext';

function Header() {
  const { cart } = useContext(CartContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navItems = [
    { to: "/zakat", text: "Zakat, Sadaqat and Kafarat" },
    { to: "/about", text: "About Us" },
    { to: "/general", text: "General Donation" }, // Add this line
  ];

  return (
    <header className="bg-white shadow-md relative">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <Link to="/">
          <img src="/logo.png" alt="Amal Care Logo" className="h-10" />
        </Link>
        
        <div className="flex items-center space-x-2">
          {/* <button className="bg-green-500 text-white px-2 py-1 text-sm rounded hover:bg-green-600 md:px-4 md:py-2 md:text-base">Donate Now</button> */}
          <button className="bg-red-500 text-white px-2 py-1 text-sm rounded hover:bg-red-600 md:px-4 md:py-2 md:text-base">Most Needed</button>
          <Link to="/general" className="bg-blue-500 text-white px-2 py-1 text-sm rounded hover:bg-blue-600 md:px-4 md:py-2 md:text-base">Donate Now</Link>
          
          {/* Dropdown Menu Button */}
          <div className="relative">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-gray-700 hover:bg-gray-100 p-2 rounded-md">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
            
            {/* Dropdown Menu */}
            {isMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-10">
                {navItems.map((item) => (
                  <Link 
                    key={item.to} 
                    to={item.to} 
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {item.text}
                  </Link>
                ))}
              </div>
            )}
          </div>
          
          {/* Cart Icon */}
          <Link to="/checkout" className="relative">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            {cart.length > 0 && (
              <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                {cart.length}
              </span>
            )}
          </Link>
        </div>
      </div>
      
      {/* Mobile Dropdown Menu */}
      {isMenuOpen && (
        <div className="md:hidden absolute left-0 right-0 bg-white shadow-md z-20">
          <div className="container mx-auto px-4 py-2">
            {navItems.map((item) => (
              <Link 
                key={item.to} 
                to={item.to} 
                className="block py-2 text-gray-700 hover:bg-gray-100 rounded-md"
                onClick={() => setIsMenuOpen(false)}
              >
                {item.text}
              </Link>
            ))}
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;

// // {/* <Link to="/" className="text-gray-700 hover:text-gray-900">Al-Awaqaf</Link>
// //           <Link to="/" className="text-gray-700 hover:text-gray-900">Seasonal</Link>
// //           <Link to="/" className="text-gray-700 hover:text-gray-900">Sharing Projects</Link> */}
     // translation attempt 

// import React, { useContext } from 'react';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import { CartContext } from '../contexts/CartContext';

// function Header() {
//   const { cart } = useContext(CartContext);
//   const { t } = useTranslation();

//   return (
//     <header className="bg-white shadow-md">
//       <div className="container mx-auto px-4 py-2 flex justify-between items-center">
//         <Link to="/">
//           <img src="/logo.png" alt="Amal Care Logo" className="h-10" />
//         </Link>
//         <nav className="hidden md:flex space-x-4">
//           <Link to="/zakat" className="text-gray-700 hover:text-gray-900 hover:bg-gray-100 px-3 py-2 rounded-md">{t('header.zakatSadaqatKafarat')}</Link>
//           <Link to="/about" className="text-gray-700 hover:text-gray-900 hover:bg-gray-100 px-3 py-2 rounded-md">{t('header.aboutUs')}</Link>
//         </nav>
//         <div className="flex items-center space-x-2">
//           <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">{t('header.innovativeDonations')}</button>
//           <button className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">{t('header.mostNeeded')}</button>
//           <Link to="/checkout" className="relative">
//             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
//             </svg>
//             {cart.length > 0 && (
//               <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
//                 {cart.length}
//               </span>
//             )}
//           </Link>
//         </div>
//       </div>
//     </header>
//   );
// }

// export default Header;

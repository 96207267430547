const isProd = process.env.NODE_ENV === 'production';

const config = {
    API_URL: isProd ? 'https://www.amalcareinc.org' : 'http://localhost:8000',
    CREATE_PAYMENT_INTENT_URL: `${isProd ? 'https://www.amalcareinc.org' : 'http://localhost:8000'}/create-payment-intent.php`,
    STRIPE_PUBLIC_KEY: isProd
        ? 'pk_live_51Phb2qRtpXKD2j6t5bE0kZEcp7QeG5orxVslVzYLtauDq5LpfW1tMPJ1qrq5FhsBtuMNUpgPxFR5D9QJPLqeF6qX00sCYCxNNv'
        : 'pk_test_51Phb2qRtpXKD2j6tyZ1P37PeLlylXq7v6tzhFDNzoGtVcPcsbDvS9AalPHDjwLtceSN8YhSEmHcaNOaOaAUkHAny00999FDEZ2',
};

export default config;
export const projects = [
  {
    name: "Support Family of 5",
    description: "Provide essential support for a family of 5 (mother and 4 children) for one month, including food and other basic necessities.",
    priceTarget: 100.0,
    currentFunding: 0.0,
    remaining: 100.0,
    recommendedCost: 100.0,
    category: "General Support",
    stripeLink: "https://donate.stripe.com/28o8wFgEE55Z5c48wZ",
    projectNumber: "P001",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 5,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/uao47wch80cd/supportfamily.png",
    isZakat: true
  },
  {
    name: "Sick Family Package",
    description: "Provide food, clothing, and medicine for a family with medical needs. $150 per month covers essential needs.",
    priceTarget: 150.0,
    currentFunding: 0.0,
    remaining: 150.0,
    recommendedCost: 150.0,
    category: "Healthcare",
    stripeLink: "https://donate.stripe.com/5kA4gp3RSaqjcEw4gK",
    projectNumber: "P002",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 5,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/kbzegv3immf4/africasickfamily.jpeg",
    isZakat: true
  },
  {
    name: "Student Orphan Education Package",
    description: "Sponsor a student orphan's education for one year, including high school and Quran studies. $750 for the full year.",
    priceTarget: 750.0,
    currentFunding: 0.0,
    remaining: 750.0,
    recommendedCost: 750.0,
    category: "Education",
    stripeLink: "https://donate.stripe.com/28ocMV6002XRawofZt",
    projectNumber: "P003",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 1,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/uxlixn1hc7eo/WhatsApp_Image_2024-08-12_at_20.27.20.jpeg",
    isZakat: true
  },
  {
    name: "Teacher Support for 20 Students",
    description: "Support a teacher for 20 students, covering their salary and resources needed. $200 per month.",
    priceTarget: 200.0,
    currentFunding: 0.0,
    remaining: 200.0,
    recommendedCost: 200.0,
    category: "Education",
    stripeLink: "https://donate.stripe.com/aEUfZ7fAA55ZbAsaFa",
    projectNumber: "P004",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 20,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/9zsoctoo2g37/africateacher.jpg",
    isZakat: false
  },
  {
    name: "Support Sudanese Refugee",
    description: "Provide essential support and aid to Sudanese refugees.",
    priceTarget: 0.0,
    currentFunding: 0.0,
    remaining: 0.0,
    recommendedCost: 0.0,
    category: "Refugee Support",
    stripeLink: "https://donate.stripe.com/28ocMV0FGaqjdIA9B7",
    projectNumber: "P005",
    country: "Sudan",
    type: "Donation",
    beneficiaryCount: 1000,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/qcqzbta3vjt0/WhatsApp_Image_2024-08-12_at_20.11.15_(1).jpeg",
    isZakat: true
  },
  {
    name: "Eye Treatment Support",
    description: "Support individuals with eye issues by providing cleaning treatments at $200 per person.",
    priceTarget: 200.0,
    currentFunding: 0.0,
    remaining: 200.0,
    recommendedCost: 200.0,
    category: "Healthcare",
    stripeLink: "https://donate.stripe.com/dR628h4VW0PJ5c43cK",
    projectNumber: "P006",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 1,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/2j5b7xcyp3he/43190358052_3a79612acc_k.jpg",
    isZakat: true
  },
  {
    name: "Malaria Vaccination",
    description: "Provide malaria vaccinations at $20 per person to prevent the spread of malaria.",
    priceTarget: 20.0,
    currentFunding: 0.0,
    remaining: 20.0,
    recommendedCost: 20.0,
    category: "Healthcare",
    stripeLink: "https://donate.stripe.com/bIYfZ7600eGzfQI28H",
    projectNumber: "P007",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 1,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/g3tlowlsuqwx/Malaria-Vaccine.png",
    isZakat: true
  },
  {
    name: "Education Support",
    description: "Sponsor the education of children by providing $50 per month for school fees and supplies.",
    priceTarget: 50.0,
    currentFunding: 0.0,
    remaining: 50.0,
    recommendedCost: 50.0,
    category: "Education",
    stripeLink: "https://donate.stripe.com/9AQ7sB2NOdCvawo8x6",
    projectNumber: "P008",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 5,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/7fucys703k0e/education.jpg",
    isZakat: true
  },
  {
    name: "Medicine Support",
    description: "Ensure that families have access to essential medicines by donating $20 per month.",
    priceTarget: 20.0,
    currentFunding: 0.0,
    remaining: 20.0,
    recommendedCost: 20.0,
    category: "Healthcare",
    stripeLink: "https://donate.stripe.com/00g3cldss55ZeME9Bb",
    projectNumber: "P009",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 1,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/dq2esguhj3ac/1000_F_580041732_C537sln3f2CYTo1QDYU6USg6WPbuKC3z.jpg",
    isZakat: true
  },
  {
    name: "Clothes Support",
    description: "Provide clothing for those in need, with $50 per month supporting a family.",
    priceTarget: 50.0,
    currentFunding: 0.0,
    remaining: 50.0,
    recommendedCost: 50.0,
    category: "Basic Needs",
    stripeLink: "https://donate.stripe.com/14k7sBeww0PJbAsfZA",
    projectNumber: "P010",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 5,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/lma0vp01jyc3/ezgif-4-181e46016d.jpg",
    isZakat: true
  },
  {
    name: "Eid Al Adha Qurbani - Cow",
    description: "Fund the Qurbani of a cow, providing meat for 7 people during Eid al-Adha. $450 covers the cost.",
    priceTarget: 450.0,
    currentFunding: 0.0,
    remaining: 450.0,
    recommendedCost: 450.0,
    category: "Religious",
    stripeLink: "https://donate.stripe.com/5kAdQZ1JKbun6g828L",
    projectNumber: "P011",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 7,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/b9dnz3woj4zk/cow.jpg",
    isZakat: false
  },
  {
    name: "Eid Al Adha Qurbani - Lamb",
    description: "Fund the Qurbani of a lamb, providing meat for individuals during Eid al-Adha. $100 covers the cost.",
    priceTarget: 100.0,
    currentFunding: 0.0,
    remaining: 100.0,
    recommendedCost: 100.0,
    category: "Religious",
    stripeLink: "https://donate.stripe.com/7sI0093RS6a3gUM28M",
    projectNumber: "P012",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 1,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/ibfrx7hbqzmu/sheed.jpeg",
    isZakat: false
  },
  {
    name: "Water Well Construction - Manual Pumping",
    description: "Construct a manual water well in rural areas, costing $1000.",
    priceTarget: 1000.0,
    currentFunding: 0.0,
    remaining: 1000.0,
    recommendedCost: 1000.0,
    category: "Infrastructure",
    stripeLink: "https://donate.stripe.com/eVa009aggaqjbAsbJn",
    projectNumber: "P013",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 1000,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/26kffbluad71/well.jpg",
    isZakat: false
  },
  {
    name: "Water Well Construction - Solar Pumping",
    description: "Construct a solar-powered water well in rural areas, costing $4000.",
    priceTarget: 4000.0,
    currentFunding: 0.0,
    remaining: 4000.0,
    recommendedCost: 4000.0,
    category: "Infrastructure",
    stripeLink: "https://donate.stripe.com/6oEdQZdss7e74803cS",
    projectNumber: "P014",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 1000,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/09u8xtsyh06t/watersolar.jpeg",
    isZakat: false
  },
  {
    name: "Support for Disabled Individuals",
    description: "Support disabled individuals with a monthly donation of $50 to $100.",
    priceTarget: 100.0,
    currentFunding: 0.0,
    remaining: 100.0,
    recommendedCost: 100.0,
    category: "Healthcare",
    stripeLink: "https://donate.stripe.com/8wMfZ7fAAeGzgUM5l1",
    projectNumber: "P015",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 10,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/fu2yykhryrfi/crutches.jpg",
    isZakat: true
  },
  {
    name: "Motorcycle Donation",
    description: "Help purchase a motorcycle for transportation needs, with costs ranging from $300 to $500.",
    priceTarget: 500.0,
    currentFunding: 0.0,
    remaining: 500.0,
    recommendedCost: 500.0,
    category: "Transportation",
    stripeLink: "https://donate.stripe.com/5kAbIR2NOaqjgUM5l2",
    projectNumber: "P016",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 1,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/id84g9eby3n2/motorcycles.jpeg",
    isZakat: false
  },
  {
    name: "Iftar and Suhur Meals",
    description: "Provide Iftar and Suhur meals for 5 people during Ramadan, costing $50 per month.",
    priceTarget: 50.0,
    currentFunding: 0.0,
    remaining: 50.0,
    recommendedCost: 50.0,
    category: "Religious",
    stripeLink: "https://donate.stripe.com/bIY28h3RSbungUMfZH",
    projectNumber: "P017",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 5,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/t3jztjezzmuf/WhatsApp_Image_2024-08-12_at_20.00.53_(1).jpeg",
    isZakat: true
  },
  {
    name: "Masjid Fundraising - Small Musallah",
    description: "Contribute to the construction of a small Musallah for 100 people, costing $25,000.",
    priceTarget: 25000.0,
    currentFunding: 0.0,
    remaining: 25000.0,
    recommendedCost: 25000.0,
    category: "Religious",
    stripeLink: "https://donate.stripe.com/8wM6oxgEE8ibfQI3cW",
    projectNumber: "P018",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 100,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/bwgnkff2tyfi/elementor-pro-3.23.2_(1).jpeg",
    isZakat: false
  },
  {
    name: "Masjid Fundraising - Medium Mosque",
    description: "Contribute to the construction of a medium-sized mosque, costing $50,000.",
    priceTarget: 50000.0,
    currentFunding: 0.0,
    remaining: 50000.0,
    recommendedCost: 50000.0,
    category: "Religious",
    stripeLink: "https://donate.stripe.com/aEUaEN744fKDbAs9Bl",
    projectNumber: "P019",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 500,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/wzo3ricflljf/WhatsApp_Image_2024-08-12_at_19.52.17.jpeg",
    isZakat: false
  },
  {
    name: "Masjid Fundraising - Large Mosque",
    description: "Contribute to the construction of a large mosque, costing $100,000.",
    priceTarget: 100000.0,
    currentFunding: 0.0,
    remaining: 100000.0,
    recommendedCost: 100000.0,
    category: "Religious",
    stripeLink: "https://donate.stripe.com/cN23clbkk8ibeMEfZK",
    projectNumber: "P020",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 1000,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/vofxlk3vdg58/WhatsApp_Image_2024-08-12_at_19.52.08.jpeg",
    isZakat: false
  },
  {
    name: "Gift Shoes for Orphan",
    description: "Gift a pair of shoes to an orphan for $10.",
    priceTarget: 10.0,
    currentFunding: 0.0,
    remaining: 10.0,
    recommendedCost: 10.0,
    category: "Basic Needs",
    stripeLink: "https://donate.stripe.com/8wM28h7440PJ7kceVH",
    projectNumber: "P021",
    country: "Various",
    type: "Donation",
    beneficiaryCount: 1,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/abhi3ey8b198/shoesorphan.jpeg",
    isZakat: true
  },
  {
    name: "Gift of Excellence: Huffaz Reward Program",
    description: "Support our annual Musabaqah Al-Mahir Bil Quran Al-Kareem in Chad, recognizing the dedication of 200 Huffaz. Your contribution rewards the top 10 male and female Quran reciters, encouraging excellence in Quranic studies.",
    priceTarget: 22000.0,
    currentFunding: 9000.0,
    remaining: 13000.0,
    recommendedCost: 100.0,
    category: "Education",
    stripeLink: "https://donate.stripe.com/default", // Replace with actual Stripe link when available
    projectNumber: "P022",
    country: "Chad",
    type: "Donation",
    beneficiaryCount: 20,
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/2t02tfecrljb/Directaid_Waqf_Islamic_Call_Endowment_2.jpg",
    isZakat: false
  }
];

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { projects } from '../data/projects';

function ProjectDetailPage() {
  const { projectId } = useParams();
  const [activeTab, setActiveTab] = useState('Description');
  const project = projects.find(p => p.projectNumber === projectId);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="md:w-1/2">
          <img src={project.image_url} alt={project.name} className="w-full rounded-lg shadow-lg" />
        </div>
        <div className="md:w-1/2">
          <h1 className="text-3xl font-bold mb-4">{project.name}</h1>
          <div className="bg-gray-100 p-4 rounded-lg mb-4">
            <div className="flex justify-between mb-2">
              <span>TARGET</span>
              <span>${project.priceTarget}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span>FUNDED</span>
              <span>${project.currentFunding}</span>
            </div>
            <div className="flex justify-between">
              <span>REMAIN</span>
              <span>${project.remaining}</span>
            </div>
          </div>
          <div className="mb-4">
            <p><strong>Category:</strong> {project.category}</p>
            <p><strong>Country:</strong> {project.country}</p>
            <p><strong>Beneficiary Count:</strong> {project.beneficiaryCount}</p>
          </div>
          <button className="bg-green-500 text-white px-6 py-2 rounded-full hover:bg-green-600 transition duration-300">
            Donate Now
          </button>
        </div>
      </div>
      <div className="mt-8">
        <div className="flex mb-4">
          <button
            className={`px-4 py-2 ${activeTab === 'Description' ? 'bg-green-500 text-white' : 'bg-gray-200'} rounded-tl-lg rounded-tr-lg`}
            onClick={() => setActiveTab('Description')}
          >
            Description
          </button>
          <button
            className={`px-4 py-2 ${activeTab === 'Features' ? 'bg-green-500 text-white' : 'bg-gray-200'} rounded-tl-lg rounded-tr-lg`}
            onClick={() => setActiveTab('Features')}
          >
            Features
          </button>
        </div>
        <div className="bg-white p-4 rounded-b-lg shadow">
          {activeTab === 'Description' ? (
            <p>{project.description}</p>
          ) : (
            <p>Features content goes here. This information is not provided in the current project data structure.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectDetailPage;

import React from 'react';

function DonationPolicy() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8">Donation Policy</h1>
      <div className="prose max-w-none">
        <p>Last updated: August 20th, 2024</p>
        
        <h2>1. Introduction</h2>
        <p>Amal Care Inc is committed to transparency and accountability in the use of donations. This policy outlines how we manage and utilize the funds generously provided by our donors to support our mission of providing aid in Africa.</p>
        
        <h2>2. Use of Donations</h2>
        <p>All donations received by Amal Care Inc are used to support our charitable mission. Specifically:</p>
        <ul>
          <li>Project Funding: The majority of donations go directly towards funding our aid projects in Africa. This includes but is not limited to providing food, clean water, healthcare, education, and emergency relief.</li>
          <li>Project-Related Expenses: A portion of donations may be used for expenses directly related to implementing our projects, such as transportation of goods, local staff wages, and necessary equipment.</li>
          <li>Administrative Costs: A small percentage of donations may be used to cover essential administrative expenses to ensure the effective operation of our organization. This includes costs related to fundraising, accounting, and regulatory compliance.</li>
        </ul>
        
        <h2>3. Allocation of Funds</h2>
        <p>When a donor contributes to a specific project or cause, we ensure that the funds are used for that particular purpose. In cases where we receive more donations than required for a specific project, or if a project is discontinued, we reserve the right to reallocate those funds to similar projects or to where the need is greatest, always in line with our mission.</p>
        
        <h2>4. Financial Accountability</h2>
        <p>Amal Care Inc is committed to financial transparency. We:</p>
        <ul>
          <li>Maintain detailed records of all donations received and how they are spent.</li>
          <li>Undergo regular financial audits by independent auditors.</li>
          <li>Publish annual reports detailing our financial activities and the impact of our work.</li>
        </ul>
        
        <h2>5. Tax Deductions</h2>
        <p>Amal Care Inc is a registered 501(c)(3) non-profit organization. Donations made by U.S. taxpayers are tax-deductible to the extent allowed by law. We provide tax receipts for all donations upon request.</p>
        
        <h2>6. Refund Policy</h2>
        <p>As a general policy, donations are non-refundable. However, in cases of demonstrable error or fraud, we will review refund requests on a case-by-case basis. Please contact us immediately if you believe an error has occurred in your donation.</p>
        
        <h2>7. Privacy</h2>
        <p>We respect the privacy of our donors. Personal information collected through the donation process is used solely for processing donations and communicating with donors. We do not sell, trade, or share personal information with third parties. For more details, please refer to our Privacy Policy.</p>
        
        <h2>8. Ethical Fundraising</h2>
        <p>Amal Care Inc adheres to ethical fundraising practices. We do not use high-pressure tactics and respect the decisions of individuals regarding their donations.</p>
        
        <h2>9. Contact Information</h2>
        <p>If you have any questions about our Donation Policy or would like more information about how your donation is being used, please contact us at:</p>
        <p>Email: contact@amalcareinc.org</p>
        <p>Phone: +1 (202)-440 6333</p>
        <p>Amal Care Inc</p>
        
        <h2>10. Policy Updates</h2>
        <p>This Donation Policy may be updated from time to time. We will post any changes on this page and update the "Last updated" date accordingly.</p>
      </div>
    </div>
  );
}

export default DonationPolicy;

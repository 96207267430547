import React from 'react';
import { Link } from 'react-router-dom';

function Banner() {
  return (
    <div className="relative h-96 bg-cover bg-center" style={{backgroundImage: "url('/gaza-banner.jpg')"}}>
      <div className="absolute inset-0 bg-green-900 bg-opacity-50"></div>
      <div className="absolute inset-0 flex flex-col justify-center items-center text-white">
        <h1 className="text-6xl font-bold mb-4">Amal Care</h1>
        <p className="text-2xl mb-8">Supporting communities in need</p>
        <Link to="/contact" className="bg-white text-green-900 px-6 py-2 rounded-full font-bold hover:bg-green-100 flex items-center">
          <span>Contact Us</span>
          <svg className="w-6 h-6 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </Link>
      </div>
    </div>
  );
}

export default Banner;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

// function Banner() {
//   const { t } = useTranslation();

//   return (
//     <div className="relative h-96 bg-cover bg-center" style={{backgroundImage: "url('/gaza-banner.jpg')"}}>
//       <div className="absolute inset-0 bg-green-900 bg-opacity-50"></div>
//       <div className="absolute inset-0 flex flex-col justify-center items-center text-white">
//         <h1 className="text-6xl font-bold mb-4">{t('hero.title')}</h1>
//         <p className="text-2xl mb-8">{t('hero.subtitle')}</p>
//         <Link to="/contact" className="bg-white text-green-900 px-6 py-2 rounded-full font-bold hover:bg-green-100 flex items-center">
//           <span>{t('hero.cta')}</span>
//           <svg className="w-6 h-6 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
//           </svg>
//         </Link>
//       </div>
//     </div>
//   );
// }

// export default Banner;

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
//import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import HomePage from './components/HomePage';
import Checkout from './components/Checkout';
import Payment from './components/Payment';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import DonationPolicy from './components/DonationPolicy';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import ZakatPage from './components/ZakatPage';
import ProjectDetailPage from './components/ProjectDetailPage';
import { CartProvider } from './contexts/CartContext';
import LanguageCurrencySelector from './components/LanguageCurrencySelector';
//import './i18n'; // Make sure to import the i18n configuration
import GeneralDonationPage from './components/GeneralDonationPage';
import config from './config';

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

function App() {
  //const { i18n } = useTranslation();
  const [currency, setCurrency] = useState('USD');

  // * WARNING: Do not remove the commented-out language switching functionality
  // const setLanguage = useCallback((lang) => {
  //   i18n.changeLanguage(lang);
  // }, [i18n]);

  // useEffect(() => {
  //   // Set the initial language based on the i18n configuration
  //   setLanguage(i18n.language);
  // }, [i18n.language, setLanguage]);

  return (
    <CartProvider>
      <Router>
        <Elements stripe={stripePromise}>
          <div className="flex flex-col min-h-screen ltr">
            <div className="bg-gray-100 py-1 px-4 flex justify-between items-center relative z-10">
              <LanguageCurrencySelector
                language="English"
                setLanguage={() => {}}
                currency={currency}
                setCurrency={setCurrency}
              />
            </div>
            <Header />
            <main className="flex-grow">
              <Routes>
                <Route path="/" element={<HomePage currency={currency} />} />
                <Route path="/zakat" element={<ZakatPage currency={currency} />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/checkout" element={<Checkout currency={currency} />} />
                <Route path="/payment" element={<Payment currency={currency} />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/donation-policy" element={<DonationPolicy />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/project/:projectId" element={<ProjectDetailPage currency={currency} />} />
                <Route path="/general" element={<GeneralDonationPage currency={currency} />} />
              </Routes>
            </main>
            <Footer />
          </div>
        </Elements>
      </Router>
    </CartProvider>
  );
}

export default App;

// import React, { useState, useEffect, useCallback } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
// import { useTranslation } from 'react-i18next';
// import Header from './components/Header';
// import HomePage from './components/HomePage';
// import Checkout from './components/Checkout';
// import Payment from './components/Payment';
// import Footer from './components/Footer';
// import PrivacyPolicy from './components/PrivacyPolicy';
// import DonationPolicy from './components/DonationPolicy';
// import ContactUs from './components/ContactUs';
// import AboutUs from './components/AboutUs';
// import ZakatPage from './components/ZakatPage';
// import { CartProvider } from './contexts/CartContext';
// import LanguageCurrencySelector from './components/LanguageCurrencySelector';
// import './i18n'; // Make sure to import the i18n configuration

// const stripePromise = loadStripe('pk_live_51Phb2qRtpXKD2j6t5bE0kZEcp7QeG5orxVslVzYLtauDq5LpfW1tMPJ1qrq5FhsBtuMNUpgPxFR5D9QJPLqeF6qX00sCYCxNNv');

// function App() {
//   const { i18n } = useTranslation();
//   const [currency, setCurrency] = useState('USD');

//   const setLanguage = useCallback((lang) => {
//     i18n.changeLanguage(lang);
//   }, [i18n]);

//   useEffect(() => {
//     // Set the initial language based on the i18n configuration
//     setLanguage(i18n.language);
//   }, [i18n.language, setLanguage]);

//   return (
//     <CartProvider>
//       <Router>
//         <Elements stripe={stripePromise}>
//           <div className={`flex flex-col min-h-screen ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
//             <div className="bg-gray-100 py-1 px-4 flex justify-between items-center relative z-10">
//               <LanguageCurrencySelector
//                 language={i18n.language}
//                 setLanguage={setLanguage}
//                 currency={currency}
//                 setCurrency={setCurrency}
//               />
//             </div>
//             <Header />
//             <main className="flex-grow">
//               <Routes>
//                 <Route path="/" element={<HomePage currency={currency} />} />
//                 <Route path="/zakat" element={<ZakatPage currency={currency} />} />
//                 <Route path="/about" element={<AboutUs />} />
//                 <Route path="/checkout" element={<Checkout currency={currency} />} />
//                 <Route path="/payment" element={<Payment currency={currency} />} />
//                 <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//                 <Route path="/donation-policy" element={<DonationPolicy />} />
//                 <Route path="/contact" element={<ContactUs />} />
//               </Routes>
//             </main>
//             <Footer />
//           </div>
//         </Elements>
//       </Router>
//     </CartProvider>
//   );
// }

// export default App;
import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
      <div className="prose max-w-none">
        <p>Last updated: August 20th, 2024</p>
        
        <h2>1. Introduction</h2>
        <p>Amal Care Inc ("we", "our", or "us") respects your privacy and is committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website and tell you about your privacy rights and how the law protects you.</p>
        
        <h2>2. The Data We Collect About You</h2>
        <p>We collect and process the following data:</p>
        <ul>
          <li>Email address (for sending donation receipts)</li>
          <li>Payment information (processed securely through Stripe)</li>
          <li>Donation amount and frequency</li>
        </ul>
        
        <h2>3. How We Use Your Personal Data</h2>
        <p>We use your personal data for the following purposes:</p>
        <ul>
          <li>To process your donation through our payment processor, Stripe</li>
          <li>To send you a receipt for your donation via email</li>
          <li>To maintain our financial records</li>
          <li>To comply with legal obligations</li>
        </ul>
        
        <h2>4. Data Sharing and Transfers</h2>
        <p>We do not sell, trade, or rent your personal information to third parties. We share your data only in the following circumstances:</p>
        <ul>
          <li>With Stripe, our payment processor, to complete your donation transaction</li>
          <li>If required by law or to respond to legal process</li>
        </ul>
        
        <h2>5. Data Security</h2>
        <p>We have implemented appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way. We limit access to your personal data to those employees and third parties who have a business need to know.</p>
        
        <h2>6. Data Retention</h2>
        <p>We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
        
        <h2>7. Your Legal Rights</h2>
        <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to:</p>
        <ul>
          <li>Request access to your personal data</li>
          <li>Request correction of your personal data</li>
          <li>Request erasure of your personal data</li>
          <li>Object to processing of your personal data</li>
          <li>Request restriction of processing your personal data</li>
          <li>Request transfer of your personal data</li>
          <li>Right to withdraw consent</li>
        </ul>
        
        <h2>8. Third-Party Links</h2>
        <p>Our website may include links to third-party websites. Clicking on those links may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements.</p>
        
        <h2>9. Changes to the Privacy Policy</h2>
        <p>We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the "Last updated" date at the top of this privacy policy.</p>
        
        <h2>10. Contact Us</h2>
        <p>If you have any questions about this privacy policy or our privacy practices, please contact us at:</p>
        <p>Email: contact@amalcareinc.org</p>
        <p>Phone: +1 (202)-440 6333</p>
        <p>Amal Care Inc</p>
        </div>
    </div>
  );
}

export default PrivacyPolicy;

// import React from 'react';
// import { useTranslation } from 'react-i18next';

// function PrivacyPolicy() {
//   const { t } = useTranslation();

//   return (
//     <div className="container mx-auto px-4 py-8">
//       <h1 className="text-4xl font-bold mb-8">{t('privacyPolicy.title')}</h1>
//       <div className="prose max-w-none">
//         <p>{t('privacyPolicy.lastUpdated', { date: 'August 20th, 2024' })}</p>
        
//         <h2>{t('privacyPolicy.introduction.title')}</h2>
//         <p>{t('privacyPolicy.introduction.content')}</p>
        
//         <h2>{t('privacyPolicy.dataCollection.title')}</h2>
//         <p>{t('privacyPolicy.dataCollection.content')}</p>
//         <ul>
//           {t('privacyPolicy.dataCollection.items', { returnObjects: true }).map((item, index) => (
//             <li key={index}>{item}</li>
//           ))}
//         </ul>
        
//         <h2>{t('privacyPolicy.dataUse.title')}</h2>
//         <p>{t('privacyPolicy.dataUse.content')}</p>
//         <ul>
//           {t('privacyPolicy.dataUse.items', { returnObjects: true }).map((item, index) => (
//             <li key={index}>{item}</li>
//           ))}
//         </ul>
        
//         <h2>{t('privacyPolicy.dataSharing.title')}</h2>
//         <p>{t('privacyPolicy.dataSharing.content')}</p>
//         <ul>
//           {t('privacyPolicy.dataSharing.items', { returnObjects: true }).map((item, index) => (
//             <li key={index}>{item}</li>
//           ))}
//         </ul>
        
//         <h2>{t('privacyPolicy.dataSecurity.title')}</h2>
//         <p>{t('privacyPolicy.dataSecurity.content')}</p>
        
//         <h2>{t('privacyPolicy.dataRetention.title')}</h2>
//         <p>{t('privacyPolicy.dataRetention.content')}</p>
        
//         <h2>{t('privacyPolicy.legalRights.title')}</h2>
//         <p>{t('privacyPolicy.legalRights.content')}</p>
//         <ul>
//           {t('privacyPolicy.legalRights.items', { returnObjects: true }).map((item, index) => (
//             <li key={index}>{item}</li>
//           ))}
//         </ul>
        
//         <h2>{t('privacyPolicy.thirdPartyLinks.title')}</h2>
//         <p>{t('privacyPolicy.thirdPartyLinks.content')}</p>
        
//         <h2>{t('privacyPolicy.changes.title')}</h2>
//         <p>{t('privacyPolicy.changes.content')}</p>
        
//         <h2>{t('privacyPolicy.contact.title')}</h2>
//         <p>{t('privacyPolicy.contact.content')}</p>
//         <p>{t('privacyPolicy.contact.email')}: contact@amalcareinc.org</p>
//         <p>{t('privacyPolicy.contact.phone')}: +1 (202)-440 6333</p>
//         <p>{t('privacyPolicy.contact.organization')}: Amal Care Inc</p>
//       </div>
//     </div>
//   );
// }

// export default PrivacyPolicy;

import React, { useState } from 'react';
import MainDonationCard from './MainDonationCard';

function ProjectTabs({ projects }) {
  const [activeTab, setActiveTab] = useState('General Support');

  const tabs = ['General Support', 'Healthcare', 'Education', 'Religious', 'Infrastructure', 'Basic Needs'];

  const filteredProjects = projects.filter(project => project.category === activeTab);

  return (
    <div className="my-8">
      <div className="flex flex-wrap justify-center mb-4">
        {tabs.map(tab => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`
              px-4 py-2 rounded m-1 transition-all duration-200 ease-in-out
              ${activeTab === tab 
                ? 'bg-green-500 text-white scale-110 shadow-lg' 
                : 'bg-gray-200 hover:bg-gray-300'
              }
              ${activeTab === tab ? 'text-sm md:text-base' : 'text-xs md:text-sm'}
            `}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredProjects.map((project, index) => (
          <MainDonationCard key={index} project={project} />
        ))}
      </div>
    </div>
  );
}

export default ProjectTabs;
// translation attempt

// import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import MainDonationCard from './MainDonationCard';

// function ProjectTabs({ projects }) {
//   const { t } = useTranslation();
//   const [activeTab, setActiveTab] = useState(t('categories.generalSupport'));

//   const tabs = [
//     'categories.generalSupport',
//     'categories.healthcare',
//     'categories.education',
//     'categories.religious',
//     'categories.infrastructure',
//     'categories.basicNeeds'
//   ];

//   const filteredProjects = projects.filter(project => project.category === activeTab);

//   return (
//     <div className="my-8">
//       <div className="flex flex-wrap justify-center mb-4">
//         {tabs.map(tab => (
//           <button
//             key={tab}
//             onClick={() => setActiveTab(t(tab))}
//             className={`
//               px-4 py-2 rounded m-1 transition-all duration-200 ease-in-out
//               ${activeTab === t(tab) 
//                 ? 'bg-green-500 text-white scale-110 shadow-lg' 
//                 : 'bg-gray-200 hover:bg-gray-300'
//               }
//               ${activeTab === t(tab) ? 'text-sm md:text-base' : 'text-xs md:text-sm'}
//             `}
//           >
//             {t(tab)}
//           </button>
//         ))}
//       </div>
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
//         {filteredProjects.map((project, index) => (
//           <MainDonationCard key={index} project={project} />
//         ))}
//       </div>
//     </div>
//   );
// }

// export default ProjectTabs;

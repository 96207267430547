import React, { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import Banner from './Banner';
import ProjectTabs from './ProjectTabs';
import MainDonationCard from './MainDonationCard';
import ZakatCard from './ZakatCard';
import Cart from './Cart';
import ThankYouCard from './ThankYouCard';
import { projects } from '../data/projects';

function HomePage() {
  const [showThankYou, setShowThankYou] = useState(false);
  const [donationDetails, setDonationDetails] = useState(null);

  useEffect(() => {
    // Check for donation success in URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get('donation_success');
    const name = urlParams.get('name');
    const amount = urlParams.get('amount');

    if (success === 'true' && name && amount) {
      setDonationDetails({ name, amount });
      setShowThankYou(true);
      // Remove the query parameters from the URL
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  const handleCloseThankYou = () => {
    setShowThankYou(false);
  };

  const huffazProject = projects.find(p => p.name === "Gift of Excellence: Huffaz Reward Program");
  
  const zakatProject = {
    name: "Zakat, Sadaqat and Kafarat",
    image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/kkw3101uuh2b/IMG_0150-e1681144408836.jpg",
  };

  return (
    <div>
      <Banner />
      <div className="container mx-auto px-4 py-8">
        <MainDonationCard project={huffazProject} isHorizontal={true} />
        <div className="my-8">
          <ZakatCard project={zakatProject} isHorizontal={true} isZakat={true}/>
        </div>
        <Cart />
        <ProjectTabs projects={projects} />
        
      </div>
      {showThankYou && donationDetails && (
        <ThankYouCard
          name={donationDetails.name}
          amount={donationDetails.amount}
          onClose={handleCloseThankYou}
        />
      )}
    </div>
  );
}

export default HomePage;


//translation attempt but issue with not everything translating, the projects disappearing, and it doing variables instead of the words like project.name 
// import React, { useState, useEffect } from 'react';
// import Banner from './Banner';
// import ProjectTabs from './ProjectTabs';
// import MainDonationCard from './MainDonationCard';
// import ZakatCard from './ZakatCard';
// import Cart from './Cart';
// import ThankYouCard from './ThankYouCard';
// import { projects } from '../data/projects';
// import { useTranslation } from 'react-i18next';

// function HomePage() {
//   const [showThankYou, setShowThankYou] = useState(false);
//   const [donationDetails, setDonationDetails] = useState(null);
//   const { t } = useTranslation();

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     const success = urlParams.get('donation_success');
//     const name = urlParams.get('name');
//     const amount = urlParams.get('amount');

//     if (success === 'true' && name && amount) {
//       setDonationDetails({ name, amount });
//       setShowThankYou(true);
//       window.history.replaceState({}, document.title, window.location.pathname);
//     }
//   }, []);

//   const handleCloseThankYou = () => {
//     setShowThankYou(false);
//   };

//   const huffazProject = projects.find(p => p.name === t("Gift of Excellence: Huffaz Reward Program"));
  
//   const zakatProject = {
//     name: t("header.zakatSadaqatKafarat"),
//     image_url: "https://storage.googleapis.com/flutterflow-io-6f20.appspot.com/projects/amal-care-inc-j1fvz1/assets/kkw3101uuh2b/IMG_0150-e1681144408836.jpg",
//     isZakat: true
//   };

//   return (
//     <div>
//       <Banner />
//       <div className="container mx-auto px-4 py-8">
//         {huffazProject && (
//           <MainDonationCard project={huffazProject} isHorizontal={true} />
//         )}
//         <div className="my-8">
//           <ZakatCard project={zakatProject} isHorizontal={true} />
//         </div>
//         <Cart />
//         <ProjectTabs projects={projects.map(project => ({
//           ...project,
//           name: t(project.name),
//           description: t(project.description)
//         }))} />
//       </div>
//       {showThankYou && donationDetails && (
//         <ThankYouCard
//           name={donationDetails.name}
//           amount={donationDetails.amount}
//           onClose={handleCloseThankYou}
//         />
//       )}
//     </div>
//   );
// }

// export default HomePage;

import React from 'react';

function AboutUs() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8">About Amal Care Inc</h1>
      <div className="prose max-w-none">
        <p>Amal Care Inc is a US-based non-profit organization dedicated to providing aid and support to communities in Africa. Our mission is to improve lives through sustainable development projects, emergency relief, and long-term programs focused on education, healthcare, and economic empowerment.</p>
        
        <h2>Our Vision</h2>
        <p>We envision a world where every individual has access to basic necessities, quality education, and opportunities for growth and prosperity.</p>
        
        <h2>Our Work</h2>
        <p>At Amal Care Inc, we focus on:</p>
        <ul>
          <li>Providing emergency relief in crisis situations</li>
          <li>Implementing sustainable development projects</li>
          <li>Supporting education initiatives</li>
          <li>Improving access to healthcare</li>
          <li>Empowering communities through economic development programs</li>
        </ul>
        
        <h2>Our Commitment</h2>
        <p>We are committed to transparency, accountability, and efficiency in our operations. We ensure that the majority of donations go directly to our projects and the communities we serve.</p>
        
        <h2>Get Involved</h2>
        <p>You can support our mission by donating, volunteering, or spreading awareness about our work. Every contribution, no matter how small, makes a difference in the lives of those we serve.</p>
      </div>
    </div>
  );
}

export default AboutUs;

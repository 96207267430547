import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../contexts/CartContext';

function Checkout() {
  const { t } = useTranslation();
  const { cart } = useContext(CartContext);
  const navigate = useNavigate();

  const totalAmount = cart.reduce((sum, item) => sum + item.donationAmount, 0);

  const handleProceedToPayment = () => {
    navigate('/payment');
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">{t('Checkout')}</h1>
      <div className="mb-4">
        <h2 className="text-xl font-semibold mb-2">{t('Order Summary')}</h2>
        {cart.map((item, index) => (
          <div key={index} className="flex justify-between mb-2">
            <span>{item.name}</span>
            <span>${item.donationAmount.toFixed(2)}</span>
          </div>
        ))}
        <div className="font-bold mt-2">
          <span>{t('Total')}: ${totalAmount.toFixed(2)}</span>
        </div>
      </div>
      <button
        onClick={handleProceedToPayment}
        className="bg-green-500 text-white px-4 py-2 rounded"
      >
        {t('Proceed to Payment')}
      </button>
    </div>
  );
}

export default Checkout;

import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../contexts/CartContext';
import { useNavigate } from 'react-router-dom';

const generalDonationProject = {
  id: "general_fund",
  name: "Amal Care General Fund",
  description: "Support Amal Care's mission to provide innovative solutions for communities in need. Your donation enables us to respond quickly to emerging challenges and implement cutting-edge programs across healthcare, education, and social welfare.",
  priceTarget: 1000000.0,
  currentFunding: 0.0,
  remaining: 1000000.0,
  recommendedCost: 100.0,
  category: "General Support",
  stripeLink: "https://donate.stripe.com/amal-care-general-fund", // Replace with actual Stripe link
  projectNumber: "AC001",
  country: "Global",
  type: "Donation",
  beneficiaryCount: null,
  image_url: "../../logo.png", // Replace with your actual logo path
  isZakat: true,
  isGeneralDonation: true
};

function GeneralDonationPage() {
  const { t } = useTranslation();
  const [donationAmount, setDonationAmount] = useState(10);
  const [isZakatDonation, setIsZakatDonation] = useState(false);
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();

  const handleAddToCart = () => {
    if (donationAmount > 0) {
      const item = {
        id: generalDonationProject.id,
        name: generalDonationProject.name,
        donationAmount: parseFloat(donationAmount),
        isZakat: isZakatDonation
      };
      console.log('GeneralDonationPage: Adding to cart:', item);
      addToCart(item);
    } else {
      console.log('GeneralDonationPage: Invalid input');
    }
  };

  const handleDonateNow = () => {
    if (donationAmount > 0) {
      const item = {
        id: generalDonationProject.id,
        name: generalDonationProject.name,
        donationAmount: parseFloat(donationAmount),
        isZakat: isZakatDonation
      };
      addToCart(item);
      navigate('/payment', { 
        state: { 
          project: generalDonationProject,
          donationAmount: parseFloat(donationAmount),
          isZakat: isZakatDonation
        } 
      });
    } else {
      console.log('GeneralDonationPage: Invalid input for donation');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">General Fund</h1>
      <p className="text-lg text-gray-700 mb-8 text-center max-w-3xl mx-auto">
        Empower Amal Care to drive innovation in charitable giving and community support. Your
        contribution to our general fund allows us to develop and implement groundbreaking solutions
        to address complex social challenges.
      </p>
      <div className="max-w-2xl mx-auto">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="p-6">
            <div className="flex justify-center mb-6">
              <img 
                src={generalDonationProject.image_url} 
                alt="Amal Care Logo" 
                className="h-32 object-contain"
              />
            </div>
            <h2 className="text-2xl font-semibold mb-4">{generalDonationProject.name}</h2>
            <p className="text-gray-600 mb-6">{generalDonationProject.description}</p>
            <div className="bg-gray-100 p-4 rounded-md mb-6">
              <div className="flex justify-between mb-2">
                <span>TARGET: ${generalDonationProject.priceTarget.toLocaleString()}</span>
                <span>FUNDED: ${generalDonationProject.currentFunding.toLocaleString()}</span>
                <span>REMAIN: ${generalDonationProject.remaining.toLocaleString()}</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-green-600 h-2.5 rounded-full" 
                  style={{width: `${(generalDonationProject.currentFunding / generalDonationProject.priceTarget) * 100}%`}}
                ></div>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="donation">
                {t('Enter your donation')}
              </label>
              <div className="flex">
                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                  $
                </span>
                <input 
                  type="number" 
                  id="donation" 
                  value={donationAmount}
                  onChange={(e) => setDonationAmount(parseFloat(e.target.value))}
                  className="rounded-none rounded-r-lg border text-gray-900 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5" 
                  placeholder="10"
                />
              </div>
            </div>
            <div className="flex items-center mb-4">
              <input 
                id="zakat-checkbox" 
                type="checkbox" 
                checked={isZakatDonation}
                onChange={() => setIsZakatDonation(!isZakatDonation)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
              />
              <label htmlFor="zakat-checkbox" className="ml-2 text-sm font-medium text-gray-900">
                {t('This donation is Zakat')}
              </label>
            </div>
            <div className="flex space-x-4">
              <button 
                className="flex-1 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
                onClick={handleDonateNow}
              >
                {t('Donate Now')}
              </button>
              <button 
                className="flex-1 border border-green-500 text-green-500 px-4 py-2 rounded hover:bg-green-50 transition duration-300"
                onClick={handleAddToCart}
              >
                {t('Add to cart')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 bg-gray-100 p-8 rounded-lg">
        <h2 className="text-2xl font-semibold mb-4">How Your Donation Makes a Difference</h2>
        <ul className="list-disc pl-5 space-y-2">
          <li>Funds cutting-edge research to identify emerging community needs</li>
          <li>Supports the development of tech-driven solutions for efficient aid distribution</li>
          <li>Enables rapid response to crisis situations with innovative approaches</li>
          <li>Fosters partnerships with leading institutions to maximize impact</li>
          <li>Invests in sustainable and scalable programs for long-term community benefit</li>
        </ul>
      </div>
    </div>
  );
}

export default GeneralDonationPage;

import React from 'react';

function ContactUs() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8">Contact Us</h1>
      <div className="grid md:grid-cols-2 gap-8">
        <div>
          <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
          <p className="mb-4">We'd love to hear from you. Please feel free to contact us with any questions or concerns.</p>
          <ul className="space-y-2">
            <li><strong>Phone:</strong> 202-440-6333</li>
            <li><strong>Email:</strong> <a href="mailto:contact@amalcareinc.org" className="text-green-600 hover:underline">contact@amalcareinc.org</a></li>
            <li><strong>Website:</strong> <a href="https://amalcareinc.org" target="_blank" rel="noopener noreferrer" className="text-green-600 hover:underline">amalcareinc.org</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-2xl font-semibold mb-4">About Us</h2>
          <p className="mb-4">Amal Care Inc is a US Non-profit organization dedicated to supporting communities in need. Our mission is to provide aid and assistance to those facing hardship, fostering hope and positive change.</p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;

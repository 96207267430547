import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../contexts/CartContext';
import { useNavigate, Link } from 'react-router-dom';

function MainDonationCard({ project, isHorizontal = false }) {
  const { t } = useTranslation();
  const [donationAmount, setDonationAmount] = useState(10);
  const [isZakatDonation, setIsZakatDonation] = useState(false);
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();

  const progress = project.isGeneralDonation ? 0 : (project.currentFunding / project.priceTarget) * 100;

  const handleAddToCart = (e) => {
    e.preventDefault(); // Prevent the link from navigating
    if (donationAmount > 0) {
      const item = {
        id: project.id,
        name: project.name,
        donationAmount: parseFloat(donationAmount),
        isZakat: project.isZakat ? isZakatDonation : false
      };
      console.log('MainDonationCard: Adding to cart:', item);
      addToCart(item);
    } else {
      console.log('MainDonationCard: Invalid input');
    }
  };

  const handleDonateNow = (e) => {
    e.preventDefault(); // Prevent the link from navigating
    if (donationAmount > 0) {
      const item = {
        id: project.id,
        name: project.name,
        donationAmount: parseFloat(donationAmount),
        isZakat: project.isZakat ? isZakatDonation : false
      };
      addToCart(item);
      navigate('/payment', { 
        state: { 
          project: project,
          donationAmount: parseFloat(donationAmount),
          isZakat: project.isZakat ? isZakatDonation : false
        } 
      });
    } else {
      console.log('MainDonationCard: Invalid input for donation');
    }
  };

  return (
    <Link to={`/project/${project.projectNumber}`} className="block">
      <div className={`bg-white shadow-lg rounded-lg overflow-hidden ${isHorizontal ? 'md:flex' : ''} h-full`}>
        <img 
          src={project.image_url} 
          alt={project.name} 
          className={`${isHorizontal ? 'md:w-1/2 h-64 md:h-auto object-cover' : 'w-full h-48 object-cover'}`} 
        />
        <div className="p-4 flex-grow flex flex-col justify-between">
          <div>
            <h2 className="text-xl font-bold mb-2">{t(project.name)}</h2>
            {!project.isGeneralDonation && <p className="text-gray-700 mb-4">{t(project.description)}</p>}
            {project.isGeneralDonation && (
              <div className="mb-4">
                <p>{t('The purpose of the donation')}</p>
                <div className="flex flex-wrap gap-2 mt-2">
                  {['Zakat', 'Sadaqat', 'Kafarat Yameen', 'Expiation Fasting', 'Nazoor or Sacrifice', 'Aqeeqa'].map((item) => (
                    <button key={item} className="px-2 py-1 bg-gray-200 rounded text-sm">{t(item)}</button>
                  ))}
                </div>
              </div>
            )}
            {!project.isGeneralDonation && (
              <div className="mb-4 bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-green-600 h-2.5 rounded-full" 
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            )}
            {!project.isGeneralDonation && (
              <div className="flex flex-wrap justify-between text-sm text-gray-600 mb-4">
                <span className="w-full sm:w-auto mb-2 sm:mb-0">{t('TARGET')}: ${project.priceTarget}</span>
                <span className="w-full sm:w-auto mb-2 sm:mb-0">{t('FUNDED')}: ${project.currentFunding}</span>
                <span className="w-full sm:w-auto">{t('REMAIN')}: ${project.remaining}</span>
              </div>
            )}
          </div>
          <div className="mt-auto" onClick={(e) => e.preventDefault()}> {/* Prevent link navigation for these elements */}
            <p className="mb-2">{t('Enter your donation')}</p>
            <div className="flex mb-4">
              <input
                type="number"
                value={donationAmount}
                onChange={(e) => setDonationAmount(parseFloat(e.target.value))}
                className="flex-grow border rounded-l px-2 py-1"
              />
              <span className="bg-green-500 text-white px-2 py-1 rounded-r">$</span>
            </div>
            {project.isZakat && (
              <label className="flex items-center mb-4">
                <input
                  type="checkbox"
                  checked={isZakatDonation}
                  onChange={() => setIsZakatDonation(!isZakatDonation)}
                  className="mr-2"
                />
                <span className="text-sm">{t('This donation is Zakat')}</span>
              </label>
            )}
            <div className="flex flex-col sm:flex-row gap-2">
              <button 
                className="w-full sm:w-1/2 bg-green-500 text-white px-4 py-2 rounded"
                onClick={handleDonateNow}
              >
                {t('Donate Now')}
              </button>
              <button 
                className="w-full sm:w-1/2 border border-green-500 text-green-500 px-4 py-2 rounded"
                onClick={handleAddToCart}
              >
                {t('Add to cart')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default MainDonationCard;


//translation attempt
// import React, { useState, useContext } from 'react';
// import { useTranslation } from 'react-i18next';
// import { CartContext } from '../contexts/CartContext';
// import { useNavigate } from 'react-router-dom';

// function MainDonationCard({ project, isHorizontal = false }) {
//   const { t } = useTranslation();
//   const [donationAmount, setDonationAmount] = useState(10);
//   const [isZakatDonation, setIsZakatDonation] = useState(false);
//   const { addToCart } = useContext(CartContext);
//   const navigate = useNavigate();

//   const progress = project.isGeneralDonation ? 0 : (project.currentFunding / project.priceTarget) * 100;

//   const handleAddToCart = () => {
//     if (donationAmount > 0) {
//       const item = {
//         id: project.id,
//         name: project.name,
//         donationAmount: parseFloat(donationAmount),
//         isZakat: project.isZakat ? isZakatDonation : false
//       };
//       console.log('MainDonationCard: Adding to cart:', item);
//       addToCart(item);
//     } else {
//       console.log('MainDonationCard: Invalid input');
//     }
//   };

//   const handleDonateNow = () => {
//     if (donationAmount > 0) {
//       const item = {
//         id: project.id,
//         name: project.name,
//         donationAmount: parseFloat(donationAmount),
//         isZakat: project.isZakat ? isZakatDonation : false
//       };
//       addToCart(item);
//       navigate('/payment', { 
//         state: { 
//           project: project,
//           donationAmount: parseFloat(donationAmount),
//           isZakat: project.isZakat ? isZakatDonation : false
//         }
//       });
//     } else {
//       console.log('MainDonationCard: Invalid input for donation');
//     }
//   };

//   return (
//     <div className={`bg-white shadow-lg rounded-lg overflow-hidden ${isHorizontal ? 'flex' : ''} h-full`}>
//       <img 
//         src={project.image_url} 
//         alt={project.name} 
//         className={`${isHorizontal ? 'w-1/2 object-cover' : 'w-full h-48 object-cover'}`} 
//       />
//       <div className="p-4 flex-grow flex flex-col justify-between">
//         <div>
//           <h2 className="text-xl font-bold mb-2">{t(project.name)}</h2>
//           {!project.isGeneralDonation && <p className="text-gray-700 mb-4">{t(project.description)}</p>}
//           {project.isGeneralDonation && (
//             <div className="mb-4">
//               <p>{t('donationCard.enterDonation')}</p>
//               <div className="flex flex-wrap gap-2 mt-2">
//                 {Object.keys(t('donationTypes', { returnObjects: true })).map((type) => (
//                   <button key={type} className="px-2 py-1 bg-gray-200 rounded">
//                     {t(`donationTypes.${type}`)}
//                   </button>
//                 ))}
//               </div>
//             </div>
//           )}
//           {!project.isGeneralDonation && (
//             <div className="mb-4 bg-gray-200 rounded-full h-2.5">
//               <div 
//                 className="bg-green-600 h-2.5 rounded-full" 
//                 style={{ width: `${progress}%` }}
//               ></div>
//             </div>
//           )}
//           {!project.isGeneralDonation && (
//             <div className="flex justify-between text-sm text-gray-600 mb-4">
//               <span>{t('donationCard.target')}: ${project.priceTarget}</span>
//               <span>{t('donationCard.funded')}: ${project.currentFunding}</span>
//               <span>{t('donationCard.remain')}: ${project.remaining}</span>
//             </div>
//           )}
//         </div>
//         <div className="mt-auto">
//           <p className="mb-2">{t('donationCard.enterDonation')}</p>
//           <div className="flex mb-4">
//             <input
//               type="number"
//               value={donationAmount}
//               onChange={(e) => setDonationAmount(parseFloat(e.target.value))}
//               className="flex-grow border rounded-l px-2 py-1"
//             />
//             <span className="bg-green-500 text-white px-2 py-1 rounded-r">$</span>
//           </div>
//           {project.isZakat && (
//             <label className="flex items-center mb-4">
//               <input
//                 type="checkbox"
//                 checked={isZakatDonation}
//                 onChange={() => setIsZakatDonation(!isZakatDonation)}
//                 className="mr-2"
//               />
//               {t('donationCard.isDonationZakat')}
//             </label>
//           )}
//           <div className="flex gap-2">
//             <button 
//               className="flex-1 bg-green-500 text-white px-4 py-2 rounded"
//               onClick={handleDonateNow}
//             >
//               {t('donationCard.donateNow')}
//             </button>
//             <button 
//               className="flex-1 border border-green-500 text-green-500 px-4 py-2 rounded"
//               onClick={handleAddToCart}
//             >
//               {t('donationCard.addToCart')}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default MainDonationCard;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../contexts/CartContext';
import { useNavigate } from 'react-router-dom';

function Cart() {
  const { t } = useTranslation();
  const { cart, removeFromCart } = useContext(CartContext);
  const navigate = useNavigate();

  const handleCheckout = () => {
    navigate('/checkout');
  };

  console.log('Cart contents:', cart); // Debug log

  return (
    <div className="bg-white shadow-lg rounded-lg p-4 mt-4">
      <h2 className="text-xl font-bold mb-4">{t('Cart')}</h2>
      {cart.length === 0 ? (
        <p>{t('Your cart is empty')}</p>
      ) : (
        cart.map((item, index) => (
          <div key={index} className="flex justify-between items-center mb-2">
            <span>{item.name}: ${item.donationAmount}</span>
            <button
              className="text-red-500"
              onClick={() => removeFromCart(index)}
            >
              {t('Remove')}
            </button>
          </div>
        ))
      )}
      <div className="font-bold mt-4">
        Total: ${cart.reduce((sum, item) => sum + item.donationAmount, 0).toFixed(2)}
      </div>
      {cart.length > 0 && (
        <button
          className="w-full bg-green-500 text-white px-4 py-2 rounded mt-4"
          onClick={handleCheckout}
        >
          {t('Proceed to Checkout')}
        </button>
      )}
    </div>
  );
}

export default Cart;

import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { projects } from '../data/projects';
import { CartContext } from '../contexts/CartContext';

function ZakatPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addToCart } = useContext(CartContext);
  const [zakatType, setZakatType] = useState(['money', 'metals', 'property', 'business']);
  const [metalValues, setMetalValues] = useState({
    'Gold': { weight: 0, rate: 80.83 },  // $80.83 per gram
    'Silver': { weight: 0, rate: 0.95 }, // $0.95 per gram
  });
  const [propertyValue, setPropertyValue] = useState(0);
  const [moneyValue, setMoneyValue] = useState(0);
  const [businessAssets, setBusinessAssets] = useState(0);
  const [businessLiabilities, setBusinessLiabilities] = useState(0);
  const [totalZakat, setTotalZakat] = useState(0);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  const zakatProjects = projects.filter(project => project.isZakat);

  const nisabThreshold = 595; // Approximate value in USD, should be updated regularly

  const calculateTotalZakat = useCallback(() => {
    let totalWealth = 0;
    let zakatableAmount = 0;

    // Calculate total wealth
    Object.entries(metalValues).forEach(([metal, { weight, rate }]) => {
      totalWealth += weight * rate;
    });
    totalWealth += propertyValue;
    totalWealth += moneyValue;
    totalWealth += businessAssets - businessLiabilities;

    // Check if total wealth is above Nisab
    if (totalWealth > nisabThreshold) {
      // Calculate Zakatable amount
      zakatableAmount = totalWealth;
      
      // Deduct non-Zakatable assets (e.g., primary residence)
      zakatableAmount -= propertyValue * 0.5; // Assuming 50% of property value is non-Zakatable

      // Calculate Zakat (2.5% of Zakatable amount)
      const zakatAmount = zakatableAmount * 0.025;
      setTotalZakat(zakatAmount);
    } else {
      setTotalZakat(0);
    }
  }, [metalValues, propertyValue, moneyValue, businessAssets, businessLiabilities]);

  useEffect(() => {
    calculateTotalZakat();
  }, [metalValues, propertyValue, moneyValue, businessAssets, businessLiabilities, calculateTotalZakat]);

  const handleMetalChange = (metal, value) => {
    setMetalValues(prev => ({
      ...prev,
      [metal]: { ...prev[metal], weight: parseFloat(value) || 0 }
    }));
  };

  const handleZakatTypeChange = (type) => {
    setZakatType(prev => {
      if (prev.includes(type)) {
        return prev.filter(t => t !== type);
      } else {
        return [...prev, type];
      }
    });
  };

  const nextProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % zakatProjects.length);
  };

  const prevProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex - 1 + zakatProjects.length) % zakatProjects.length);
  };

  const handleAddToCart = () => {
    if (selectedProject && totalZakat > 0) {
      const item = {
        id: selectedProject.id || 'zakat-1',
        name: `${selectedProject.name} - Zakat`,
        donationAmount: totalZakat,
        isZakat: true
      };
      console.log('ZakatPage: Adding to cart:', item);
      addToCart(item);
    } else {
      console.log('ZakatPage: Invalid input for cart');
    }
  };

  const handleDonation = () => {
    if (selectedProject && totalZakat > 0) {
      const item = {
        id: selectedProject.id || 'zakat-1',
        name: `${selectedProject.name} - Zakat`,
        donationAmount: totalZakat,
        isZakat: true
      };
      addToCart(item);
      navigate('/checkout', { 
        state: { 
          project: {
            ...selectedProject,
            name: `${selectedProject.name} - Zakat`,
            isZakat: true
          },
          donationAmount: totalZakat,
          isZakat: true
        } 
      });
    } else {
      console.log('ZakatPage: Invalid input for donation');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-4">{t('Zakat Calculator')}</h2>
      <p className="mb-4">{t('The Zakat Calculator helps you calculate your Zakat based on your assets. Zakat is payable if your wealth exceeds the Nisab threshold.')}</p>
      
      <div className="mb-6">
        <h3 className="font-bold mb-2">{t('Choose Zakat Type')}</h3>
        <div className="flex space-x-4">
          {['money', 'metals', 'property', 'business'].map(type => (
            <button
              key={type}
              className={`px-4 py-2 rounded ${zakatType.includes(type) ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
              onClick={() => handleZakatTypeChange(type)}
            >
              {t(type)}
            </button>
          ))}
        </div>
      </div>

      <hr className="my-6" />

      {zakatType.includes('metals') && (
        <div className="mb-6">
          <h3 className="font-bold mb-2">{t('Precious Metals')}</h3>
          <table className="w-full">
            <thead>
              <tr>
                <th>{t('Type')}</th>
                <th>{t('Weight (Grams)')}</th>
                <th>{t('Today\'s rate (per gram)')}</th>
                <th>{t('Value')}</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(metalValues).map(([metal, { weight, rate }]) => (
                <tr key={metal}>
                  <td>{t(metal)}</td>
                  <td>
                    <input
                      type="number"
                      value={weight}
                      onChange={(e) => handleMetalChange(metal, e.target.value)}
                      className="w-full border rounded px-2 py-1"
                    />
                  </td>
                  <td>${rate.toFixed(2)}</td>
                  <td>${(weight * rate).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <hr className="my-6" />

      {zakatType.includes('property') && (
        <div className="mb-6">
          <h3 className="font-bold mb-2">{t('Property')}</h3>
          <div className="flex items-center">
            <span className="mr-2">$</span>
            <input
              type="number"
              value={propertyValue}
              onChange={(e) => setPropertyValue(parseFloat(e.target.value) || 0)}
              className="w-full border rounded px-2 py-1"
            />
          </div>
        </div>
      )}

      <hr className="my-6" />

      {zakatType.includes('money') && (
        <div className="mb-6">
          <h3 className="font-bold mb-2">{t('Money')}</h3>
          <div className="flex items-center">
            <span className="mr-2">$</span>
            <input
              type="number"
              value={moneyValue}
              onChange={(e) => setMoneyValue(parseFloat(e.target.value) || 0)}
              className="w-full border rounded px-2 py-1"
            />
          </div>
        </div>
      )}

      <hr className="my-6" />

      {zakatType.includes('business') && (
        <div className="mb-6">
          <h3 className="font-bold mb-2">{t('Business Assets and Liabilities')}</h3>
          <div className="flex items-center mb-2">
            <span className="mr-2">{t('Assets')} $</span>
            <input
              type="number"
              value={businessAssets}
              onChange={(e) => setBusinessAssets(parseFloat(e.target.value) || 0)}
              className="w-full border rounded px-2 py-1"
            />
          </div>
          <div className="flex items-center">
            <span className="mr-2">{t('Liabilities')} $</span>
            <input
              type="number"
              value={businessLiabilities}
              onChange={(e) => setBusinessLiabilities(parseFloat(e.target.value) || 0)}
              className="w-full border rounded px-2 py-1"
            />
          </div>
        </div>
      )}

      <hr className="my-6" />

      <div className="mb-6">
        <h3 className="font-bold mb-2">{t('Choose Zakat project')}</h3>
        <div className="flex justify-between items-center">
          <p>{selectedProject ? selectedProject.name : 'Please Select A Package'}</p>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded"
            onClick={() => setShowProjectModal(true)}
          >
            {t('Select')}
          </button>
        </div>
      </div>

      <hr className="my-6" />

      <div className="mb-6">
        <h3 className="font-bold mb-2">{t('Total Zakat')}</h3>
        <p className="text-2xl font-bold text-green-500">${totalZakat.toFixed(2)}</p>
      </div>

      <div className="flex gap-2">
        <button
          className="flex-1 bg-green-500 text-white px-4 py-2 rounded"
          onClick={handleDonation}
          disabled={totalZakat <= 0 || !selectedProject}
        >
          {t('Donate Now')}
        </button>
        <button
          className="flex-1 border border-green-500 text-green-500 px-4 py-2 rounded"
          onClick={handleAddToCart}
          disabled={totalZakat <= 0 || !selectedProject}
        >
          {t('Add to cart')}
        </button>
      </div>

      {showProjectModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg max-w-3xl w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">{t('Choose Zakat project')}</h3>
              <button onClick={() => setShowProjectModal(false)}>×</button>
            </div>
            <div className="flex justify-between items-center mb-4">
              <button onClick={prevProject}>{'<'}</button>
              <img src={zakatProjects[currentProjectIndex].image_url} alt={zakatProjects[currentProjectIndex].name} className="w-64 h-64 object-cover" />
              <button onClick={nextProject}>{'>'}</button>
            </div>
            <p className="text-center mb-4">{zakatProjects[currentProjectIndex].name}</p>
            <button
              className="w-full bg-green-500 text-white px-4 py-2 rounded"
              onClick={() => {
                setSelectedProject(zakatProjects[currentProjectIndex]);
                setShowProjectModal(false);
              }}
            >
              {t('Select')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ZakatPage;

// //translation attempt with issues
// import React, { useState, useEffect, useCallback, useContext } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
// import { projects } from '../data/projects';
// import { CartContext } from '../contexts/CartContext';

// function ZakatPage() {
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const { addToCart } = useContext(CartContext);
//   const [zakatType, setZakatType] = useState(['money', 'metals', 'property', 'business']);
//   const [metalValues, setMetalValues] = useState({
//     'Gold': { weight: 0, rate: 80.83 },
//     'Silver': { weight: 0, rate: 0.95 },
//   });
//   const [propertyValue, setPropertyValue] = useState(0);
//   const [moneyValue, setMoneyValue] = useState(0);
//   const [businessAssets, setBusinessAssets] = useState(0);
//   const [businessLiabilities, setBusinessLiabilities] = useState(0);
//   const [totalZakat, setTotalZakat] = useState(0);
//   const [showProjectModal, setShowProjectModal] = useState(false);
//   const [selectedProject, setSelectedProject] = useState(null);
//   const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

//   const zakatProjects = projects.filter(project => project.isZakat);

//   const nisabThreshold = 595;

//   const calculateTotalZakat = useCallback(() => {
//     let totalWealth = 0;
//     let zakatableAmount = 0;

//     Object.entries(metalValues).forEach(([metal, { weight, rate }]) => {
//       totalWealth += weight * rate;
//     });
//     totalWealth += propertyValue;
//     totalWealth += moneyValue;
//     totalWealth += businessAssets - businessLiabilities;

//     if (totalWealth > nisabThreshold) {
//       zakatableAmount = totalWealth;
//       zakatableAmount -= propertyValue * 0.5;
//       const zakatAmount = zakatableAmount * 0.025;
//       setTotalZakat(zakatAmount);
//     } else {
//       setTotalZakat(0);
//     }
//   }, [metalValues, propertyValue, moneyValue, businessAssets, businessLiabilities]);

//   useEffect(() => {
//     calculateTotalZakat();
//   }, [metalValues, propertyValue, moneyValue, businessAssets, businessLiabilities, calculateTotalZakat]);

//   const handleMetalChange = (metal, value) => {
//     setMetalValues(prev => ({
//       ...prev,
//       [metal]: { ...prev[metal], weight: parseFloat(value) || 0 }
//     }));
//   };

//   const handleZakatTypeChange = (type) => {
//     setZakatType(prev => {
//       if (prev.includes(type)) {
//         return prev.filter(t => t !== type);
//       } else {
//         return [...prev, type];
//       }
//     });
//   };

//   const nextProject = () => {
//     setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % zakatProjects.length);
//   };

//   const prevProject = () => {
//     setCurrentProjectIndex((prevIndex) => (prevIndex - 1 + zakatProjects.length) % zakatProjects.length);
//   };

//   const handleAddToCart = () => {
//     if (selectedProject && totalZakat > 0) {
//       const item = {
//         id: selectedProject.id || 'zakat-1',
//         name: `${selectedProject.name} - Zakat`,
//         donationAmount: totalZakat,
//         isZakat: true
//       };
//       console.log('ZakatPage: Adding to cart:', item);
//       addToCart(item);
//     } else {
//       console.log('ZakatPage: Invalid input for cart');
//     }
//   };

//   const handleDonation = () => {
//     if (selectedProject && totalZakat > 0) {
//       const item = {
//         id: selectedProject.id || 'zakat-1',
//         name: `${selectedProject.name} - Zakat`,
//         donationAmount: totalZakat,
//         isZakat: true
//       };
//       addToCart(item);
//       navigate('/checkout', { 
//         state: { 
//           project: {
//             ...selectedProject,
//             name: `${selectedProject.name} - Zakat`,
//             isZakat: true
//           },
//           donationAmount: totalZakat,
//           isZakat: true
//         } 
//       });
//     } else {
//       console.log('ZakatPage: Invalid input for donation');
//     }
//   };

//   return (
//     <div className="container mx-auto px-4 py-8">
//       <h2 className="text-2xl font-bold mb-4">{t('Zakat Calculator')}</h2>
//       <p className="mb-4">{t('The Zakat Calculator helps you calculate your Zakat based on your assets. Zakat is payable if your wealth exceeds the Nisab threshold.')}</p>
      
//       <div className="mb-6">
//         <h3 className="font-bold mb-2">{t('Choose Zakat Type')}</h3>
//         <div className="flex space-x-4">
//           {['money', 'metals', 'property', 'business'].map(type => (
//             <button
//               key={type}
//               className={`px-4 py-2 rounded ${zakatType.includes(type) ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
//               onClick={() => handleZakatTypeChange(type)}
//             >
//               {t(type)}
//             </button>
//           ))}
//         </div>
//       </div>

//       <hr className="my-6" />

//       {zakatType.includes('metals') && (
//         <div className="mb-6">
//           <h3 className="font-bold mb-2">{t('Precious Metals')}</h3>
//           <table className="w-full">
//             <thead>
//               <tr>
//                 <th>{t('Type')}</th>
//                 <th>{t('Weight (Grams)')}</th>
//                 <th>{t('Today\'s rate (per gram)')}</th>
//                 <th>{t('Value')}</th>
//               </tr>
//             </thead>
//             <tbody>
//               {Object.entries(metalValues).map(([metal, { weight, rate }]) => (
//                 <tr key={metal}>
//                   <td>{t(metal)}</td>
//                   <td>
//                     <input
//                       type="number"
//                       value={weight}
//                       onChange={(e) => handleMetalChange(metal, e.target.value)}
//                       className="w-full border rounded px-2 py-1"
//                     />
//                   </td>
//                   <td>${rate.toFixed(2)}</td>
//                   <td>${(weight * rate).toFixed(2)}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )}

//       <hr className="my-6" />

//       {zakatType.includes('property') && (
//         <div className="mb-6">
//           <h3 className="font-bold mb-2">{t('Property')}</h3>
//           <div className="flex items-center">
//             <span className="mr-2">$</span>
//             <input
//               type="number"
//               value={propertyValue}
//               onChange={(e) => setPropertyValue(parseFloat(e.target.value) || 0)}
//               className="w-full border rounded px-2 py-1"
//             />
//           </div>
//         </div>
//       )}

//       <hr className="my-6" />

//       {zakatType.includes('money') && (
//         <div className="mb-6">
//           <h3 className="font-bold mb-2">{t('Money')}</h3>
//           <div className="flex items-center">
//             <span className="mr-2">$</span>
//             <input
//               type="number"
//               value={moneyValue}
//               onChange={(e) => setMoneyValue(parseFloat(e.target.value) || 0)}
//               className="w-full border rounded px-2 py-1"
//             />
//           </div>
//         </div>
//       )}

//       <hr className="my-6" />

//       {zakatType.includes('business') && (
//         <div className="mb-6">
//           <h3 className="font-bold mb-2">{t('Business Assets and Liabilities')}</h3>
//           <div className="flex items-center mb-2">
//             <span className="mr-2">{t('Assets')} $</span>
//             <input
//               type="number"
//               value={businessAssets}
//               onChange={(e) => setBusinessAssets(parseFloat(e.target.value) || 0)}
//               className="w-full border rounded px-2 py-1"
//             />
//           </div>
//           <div className="flex items-center">
//             <span className="mr-2">{t('Liabilities')} $</span>
//             <input
//               type="number"
//               value={businessLiabilities}
//               onChange={(e) => setBusinessLiabilities(parseFloat(e.target.value) || 0)}
//               className="w-full border rounded px-2 py-1"
//             />
//           </div>
//         </div>
//       )}

//       <hr className="my-6" />

//       <div className="mb-6">
//         <h3 className="font-bold mb-2">{t('Choose Zakat project')}</h3>
//         <div className="flex justify-between items-center">
//           <p>{selectedProject ? selectedProject.name : t('Please Select A Package')}</p>
//           <button
//             className="bg-green-500 text-white px-4 py-2 rounded"
//             onClick={() => setShowProjectModal(true)}
//           >
//             {t('Select')}
//           </button>
//         </div>
//       </div>

//       <hr className="my-6" />

//       <div className="mb-6">
//         <h3 className="font-bold mb-2">{t('Total Zakat')}</h3>
//         <p className="text-2xl font-bold text-green-500">${totalZakat.toFixed(2)}</p>
//       </div>

//       <div className="flex gap-2">
//         <button
//           className="flex-1 bg-green-500 text-white px-4 py-2 rounded"
//           onClick={handleDonation}
//           disabled={totalZakat <= 0 || !selectedProject}
//         >
//           {t('Donate Now')}
//         </button>
//         <button
//           className="flex-1 border border-green-500 text-green-500 px-4 py-2 rounded"
//           onClick={handleAddToCart}
//           disabled={totalZakat <= 0 || !selectedProject}
//         >
//           {t('Add to cart')}
//         </button>
//       </div>

//       {showProjectModal && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
//           <div className="bg-white p-6 rounded-lg max-w-3xl w-full">
//             <div className="flex justify-between items-center mb-4">
//               <h3 className="text-xl font-bold">{t('Choose Zakat project')}</h3>
//               <button onClick={() => setShowProjectModal(false)}>×</button>
//             </div>
//             <div className="flex justify-between items-center mb-4">
//               <button onClick={prevProject}>{'<'}</button>
//               <img src={zakatProjects[currentProjectIndex].image_url} alt={zakatProjects[currentProjectIndex].name} className="w-64 h-64 object-cover" />
//               <button onClick={nextProject}>{'>'}</button>
//             </div>
//             <p className="text-center mb-4">{zakatProjects[currentProjectIndex].name}</p>
//             <button
//               className="w-full bg-green-500 text-white px-4 py-2 rounded"
//               onClick={() => {
//                 setSelectedProject(zakatProjects[currentProjectIndex]);
//                 setShowProjectModal(false);
//               }}
//             >
//               {t('Select')}
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default ZakatPage;

import React, { useState, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import config from '../config';

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

function CheckoutForm({ totalAmount }) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const { t } = useTranslation();
  const { cart, clearCart } = useContext(CartContext);
  const navigate = useNavigate();
  const handlePaymentSuccess = async (paymentIntent) => {
    try {
      const name = paymentIntent?.billing_details?.name || 'Donor';
      const email = paymentIntent?.billing_details?.email || '';
      const amount = ((paymentIntent?.amount || 0) / 100).toFixed(2);
  
      const response = await fetch(`${config.API_URL}/api/payment-success.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ paymentIntent }),
      });
      
      const result = await response.json();
      
      if (result.status === 'success') {
        clearCart();
        navigate(`/?donation_success=true&name=${encodeURIComponent(name)}&amount=${encodeURIComponent(amount)}`);
      } else {
        throw new Error(result.message || 'Payment confirmation failed');
      }
    } catch (error) {
      console.error('Error confirming payment on server:', error);
      setError('An error occurred while confirming your payment. Please contact support.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const name = event.target.name.value;
    const email = event.target.email.value;

    try {
      const response = await fetch(config.CREATE_PAYMENT_INTENT_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          cart, 
          amount: totalAmount,
          donor: { name, email }
        }),
      });
          
      const data = await response.json();

      if (!data.clientSecret) {
        throw new Error('Failed to create payment intent');
      }

      const result = await stripe.confirmCardPayment(data.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: { name, email },
        }
      });

      if (result.error) {
        setError(result.error.message);
        setProcessing(false);
      } else {
        setError(null);
        setSucceeded(true);
        await handlePaymentSuccess(result.paymentIntent);
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block mb-1">{t('Full Name')}</label>
        <input type="text" name="name" required className="w-full border rounded px-2 py-1" />
      </div>
      <div>
        <label className="block mb-1">{t('Email')}</label>
        <input type="email" name="email" required className="w-full border rounded px-2 py-1" />
      </div>
      <div>
        <label className="block mb-1">{t('Card Details')}</label>
        <CardElement className="border rounded px-2 py-3" />
      </div>
      {error && <div className="text-red-500">{error}</div>}
      <button 
        type="submit" 
        disabled={!stripe || processing || succeeded}
        className="bg-green-500 text-white px-4 py-2 rounded w-full"
      >
        {processing ? t('Processing') : t('Pay')} ${totalAmount.toFixed(2)}
      </button>
      {succeeded && <div className="text-green-500 mt-4">{t('Payment successful! Thank you for your donation.')}</div>}
    </form>
  );
}

function Payment() {
  const { cart } = useContext(CartContext);
  const { t } = useTranslation();

  const totalAmount = cart.reduce((sum, item) => sum + item.donationAmount, 0);

  return (
    <div className="container mx-auto p-4 max-w-md">
      <h1 className="text-2xl font-bold mb-4">{t('Donation Payment')}</h1>
      <div className="mb-4">
        <h2 className="text-xl font-semibold mb-2">{t('Order Summary')}</h2>
        {cart.map((item, index) => (
          <div key={index} className="flex justify-between mb-2">
            <span>{item.name}</span>
            <span>${item.donationAmount.toFixed(2)}</span>
          </div>
        ))}
        <div className="font-bold mt-2">
          <span>{t('Total Donation')}: ${totalAmount.toFixed(2)}</span>
        </div>
      </div>
      <Elements stripe={stripePromise}>
        <CheckoutForm totalAmount={totalAmount} />
      </Elements>
    </div>
  );
}

export default Payment;

import React from 'react';

const ThankYouCard = ({ name, amount, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4 text-green-600">Thank You for Your Donation!</h2>
        <p className="mb-4">Dear {name},</p>
        <p className="mb-4">
          We sincerely thank you for your generous donation of ${amount} to Amal Care Inc.
        </p>
        <p className="mb-6">
          Your support helps us continue our mission to make a positive impact in our community.
        </p>
        <p className="mb-4">Best regards,<br />The Amal Care Team</p>
        <button
          onClick={onClose}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ThankYouCard;

// import React from 'react';
// import { useTranslation } from 'react-i18next';

// const ThankYouCard = ({ name, amount, onClose }) => {
//   const { t } = useTranslation();

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//       <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
//         <h2 className="text-2xl font-bold mb-4 text-green-600">{t('thankYou.title')}</h2>
//         <p className="mb-4">{t('thankYou.greeting', { name })},</p>
//         <p className="mb-4">
//           {t('thankYou.message', { amount: `$${amount}` })}
//         </p>
//         <p className="mb-6">
//           {t('thankYou.impact')}
//         </p>
//         <p className="mb-4">{t('thankYou.regards')}<br />{t('thankYou.team')}</p>
//         <button
//           onClick={onClose}
//           className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
//         >
//           {t('thankYou.close')}
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ThankYouCard;
